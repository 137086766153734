<template>
    <t-row>
        <t-col :span="6">
            <t-input
                size="large"
                v-model="value"
                clearable
                placeholder="请输入图标关键词"
                @change="onInput"
            >
                <template #prefixIcon>
                    <search-icon/>
                </template>
            </t-input>
        </t-col>
    </t-row>
</template>

<script>
export default {
    props: {
        searchValue: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            value: this.searchValue,
        };
    },
    methods: {
        onInput(value) {
            this.$emit("change", value); // 触发 change 事件，将新的值传递给父组件
        },
    },
};
</script>

<style scoped></style>
