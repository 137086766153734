<template>
    <ClientOnly>
        <div class="icon-page">
            <NavBar />
            <section class="icon-content">
                <main class="icon-content-main">
                    <div class="icon-store">
                        <!-- 左侧导航 -->
                        <div class="icon-store-anchors">
                            <scrollactive
                                class="anchors-list"
                                v-bind="scrollactiveOptions"
                            >
                                <a
                                    v-for="item in menuData"
                                    :key="item.category"
                                    class="anchors-link scrollactive-item"
                                    :href="`#${item.category}`"
                                >
                                    {{ item.categoryCN }}
                                </a>
                            </scrollactive>
                        </div>
                        <!-- 右侧图标列表 -->
                        <div class="icon-store-content">
                            <!-- 搜索 -->
                            <div class="icon-store-content-tool">
                                <IconSearch
                                    :search-value="search"
                                    @change="onSearchChange"
                                />
                            </div>
                            <IconPanelList
                                :icons-data="menuData"
                                :config="config"
                            />
                        </div>
                    </div>
                </main>
                <!-- 工具栏 -->
                <aside class="icon-content-aside">
                    <IconAside @change="onChangeConfig" />
                </aside>
            </section>
        </div>
    </ClientOnly>
</template>

<script>
import NavBar from "./IconNavbar.vue";
import IconSearch from "./IconSearch.vue";
import IconPanelList from "./IconPanelList.vue";
import IconAside from "./IconAside.vue";
import { cloneDeep, getMenu, defaultConfig, svgToBase64 } from "../utils";
import { getSvgCode } from "../utils/build-svg";
export default {
    name: "IconHome",
    components: {
        NavBar,
        IconSearch,
        IconPanelList,
        IconAside,
    },
    data() {
        return {
            search: "",
            menuData: getMenu(),
            scrollactiveOptions: {
                activeClass: "active",
                duration: 10,
                bezierEasingValue: "0.5,0,0.35,1",
                scrollContainerSelector: ".icon-store-content-list-wrapper",
            },
            config: cloneDeep(defaultConfig),
        };
    },
    methods: {
        onSearchChange(val) {
            this.search = val;
        },
        onChangeConfig(data) {
            this.config = data;
            this.updateSvg(data);
        },
        getBase64Url(config, name) {
            const props = {
                ...config,
                colors: config.colors.concat(Array(4).fill("")).slice(0, 4),
            };
            const svgTemplate = getSvgCode(name);
            const svgContent = svgTemplate(props);
            const base64 = svgToBase64(svgContent);
            return { base64, svgContent };
        },
        updateSvg(configData) {
            const newData = cloneDeep(this.menuData);
            this.menuData = newData.map((item) => {
                item.list = item.list.map((item2) => {
                    const { svgContent, base64 } = this.getBase64Url(
                        configData,
                        item2.name
                    );
                    item2.base64Url = base64;
                    item2.svg = svgContent;
                    return item2;
                });
                return item;
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
$contentHeight = 100svh - $navbarHeight;
.navbar {
    position: sticky;
    top: 0
}
.icon-page {
    background-color: $homeBgColor;
}
.icon-content {
    display: flex;
    background-color: $homeBgColor;
    height: $contentHeight;
    overflow hidden
}
.icon-content-main {
    flex: 1;
    overflow: auto;
    height: auto;
    .icon-store {
        display: flex;
        height: 100%;
    }
    .icon-store-anchors {
        width: $homeMenuWidth;
        overflow-y: auto;
        border-right: 1px solid $borderColor;
        .anchors-list {
            list-style: none;
            margin: 0;
            padding: 10px 0;
        }
        .anchors-link {
            padding: 0 20px;
            flex: none;
            height: 40px;
            text-align: left;
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 40px;
            font-size: 14px;
            color: $homeMenuColor;
            cursor: pointer;
            font-weight: 500;
            margin-bottom: 4px;
            box-sizing: border-box;
            &:hover {
                color: $homeMenuHoverColor;
                background-color: #f8f9fa;
            }
            &.active {
                font-weight: 600;
                color: $homeMenuHoverColor;
                background-color: #f8f9fa;
            }
        }
    }
    .icon-store-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: $contentHeight;
        overflow: hidden scroll;
        &-tool {
            position: sticky;
            top: 0;
            margin: 36px $homeSpace;
            background-color: $homeBgColor;
        }
    }
}
.icon-content-aside {
    width: 300px;
    background: #F7F8FB;
    overflow-y: auto;
}
</style>
